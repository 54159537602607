import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Container.module.scss';

function Container({ className, ...props }) {
  return <div {...props} className={cn(styles.container, className)} />;
}

Container.propTypes = {
  className: PropTypes.string
};

Container.defaultProps = {
  className: null
};

export default Container;
