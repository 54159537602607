import merge from 'lodash/merge';

const FETCH_TIMEOUT_MILLIS = 10000;

const timeoutPromise = (promise, timeout, error) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(error);
    }, timeout);
    promise.then(resolve, reject);
  });

const defaults = {
  baseUrl: '',
  url: '',
  method: 'GET',
  headers: {}
};

class fetcher {
  constructor(props) {
    this.config = props;
  }

  getQueryString = params => {
    const esc = encodeURIComponent;

    return Object.keys(params || {})
      .map(k => `${esc(k)}=${esc(params[k])}`)
      .join('&');
  };

  apiCall = configurator => async (
    payload = {},
    { authorization, ...meta } = {}
  ) => {
    const { baseUrl, url, body, params, ...options } = merge(
      {},
      defaults,
      {
        headers: {
          ...(authorization ? { Authorization: authorization } : {})
        }
      },
      this.config,
      meta,
      configurator(payload, meta)
    );

    const qs = this.getQueryString(params);

    // Network errors are thrown here
    const fetchPromise = fetch(`${baseUrl}${url}${qs ? '?' : ''}${qs}`, {
      ...options,
      body: JSON.stringify(body)
    });

    const response = await timeoutPromise(
      fetchPromise,
      FETCH_TIMEOUT_MILLIS,
      'TIMEOUT'
    );

    const data = await response.body;

    if (response.ok) {
      return {
        headers: response.headers,
        data
      };
    }

    throw new Error('Failed');
  };
}

export default fetcher;
