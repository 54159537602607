import React, { useCallback, useState, useEffect } from 'react';
import cn from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';
import cookies from 'js-cookie';
import NavLink from '../NavLink';
import Button from '../Button';
import Container from '../Container';
import * as paths from '../../paths';
import styles from './CookieConsentBar.module.scss';

const messages = defineMessages({
  description: {
    id: 'cookieConsentBar.description',
    defaultMessage:
      'This website uses cookies to ensure you get the best experience on our website.'
  },
  button: {
    id: 'cookieConsentBar.button',
    defaultMessage: 'Got it!'
  },
  link: {
    id: 'cookieConsentBar.link',
    defaultMessage: 'Privacy Policy'
  }
});

function CookieConsentBar() {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
    cookies.set('cookiesAccepted', true);
  }, [setIsOpen]);

  useEffect(() => {
    setIsOpen(!cookies.get('cookiesAccepted'));
  }, [setIsOpen]);

  return (
    <div
      className={cn(styles.container, {
        [styles.invisible]: !isOpen
      })}
    >
      <Container className={styles.description}>
        <div>
          <FormattedMessage {...messages.description} />
          <NavLink
            activeClassName={styles.active}
            className={styles.item}
            exact
            to={paths.privacyPolicy()}
          >
            <FormattedMessage {...messages.link} />
          </NavLink>
        </div>
        <div className={styles.button}>
          <Button variant="contained" color="secondary" onClick={onClose}>
            <FormattedMessage {...messages.button} />
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default CookieConsentBar;
