import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import { isMessageDefinition } from '../../utils/message';
import styles from './TextInput.module.scss';

class TextInput extends Component {
  handleChange = e => {
    const { onChange } = this.props;
    onChange(e.target.value);
  };

  render() {
    const {
      name,
      type,
      value,
      onChange,
      form,
      placeholder,
      errorMessage,
      intl,
      ...rest
    } = this.props;

    const renderPlaceholder = () =>
      isMessageDefinition(placeholder)
        ? intl.formatMessage(placeholder, placeholder.values)
        : placeholder;

    const renderErrorMessage = () =>
      isMessageDefinition(errorMessage)
        ? intl.formatMessage(errorMessage, errorMessage.values)
        : errorMessage;

    return (
      <>
        <div className={styles.container}>
          <input
            {...rest}
            name={name}
            value={value || ''}
            onChange={this.handleChange}
            className={cn(styles.input, {
              [styles.error]: !!errorMessage
            })}
            placeholder={renderPlaceholder()}
          />
          {errorMessage && (
            <p className={styles.errorText}>* {renderErrorMessage()}</p>
          )}
        </div>
      </>
    );
  }
}

TextInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  errorMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  form: PropTypes.shape({}),
  type: PropTypes.oneOf(['text', 'number', 'email', 'password']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func
};

TextInput.defaultProps = {
  name: null,
  form: {},
  type: 'text',
  value: '',
  onChange: () => {},
  errorMessage: null,
  placeholder: null
};

export default injectIntl(TextInput);
