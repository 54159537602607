import React from 'react';

function QuoteIcon(props) {
  return (
    <svg
      width="21px"
      height="20px"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-789.000000, -3800.000000)" fill="currentColor">
          <path
            d="M790.17,3813.6 C789.796665,3813.6 789.505001,3813.46 789.295,3813.18 C789.084999,3812.9 789.026666,3812.55 789.12,3812.13 L790.66,3802.33 C790.846668,3800.97666 791.499995,3800.3 792.62,3800.3 L797.1,3800.3 C797.426668,3800.3 797.694999,3800.41667 797.905,3800.65 C798.115001,3800.88334 798.22,3801.16333 798.22,3801.49 C798.22,3801.95667 798.150001,3802.33 798.01,3802.61 L794.37,3812.06 C794.136666,3812.57334 793.880001,3812.95833 793.6,3813.215 C793.319999,3813.47167 792.900003,3813.6 792.34,3813.6 L790.17,3813.6 Z M801.16,3813.6 C800.786665,3813.6 800.495001,3813.46 800.285,3813.18 C800.074999,3812.9 800.016666,3812.55 800.11,3812.13 L801.65,3802.33 C801.836668,3800.97666 802.489994,3800.3 803.61,3800.3 L808.09,3800.3 C808.416668,3800.3 808.684999,3800.41667 808.895,3800.65 C809.105001,3800.88334 809.21,3801.16333 809.21,3801.49 C809.21,3801.95667 809.140001,3802.33 809,3802.61 L805.36,3812.06 C805.126665,3812.57334 804.870001,3812.95833 804.59,3813.215 C804.309999,3813.47167 803.890003,3813.6 803.33,3813.6 L801.16,3813.6 Z"
            transform="translate(799.143461, 3806.950001) scale(-1, 1) translate(-799.143461, -3806.950001) "
          />
        </g>
      </g>
    </svg>
  );
}

export default QuoteIcon;
