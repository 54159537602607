import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { isMessageDefinition } from '../../utils/message';
import styles from './TechInfo.module.scss';

function TechInfo(props) {
  const { title, intl } = props;

  const renderTitle = () =>
    isMessageDefinition(title)
      ? intl.formatMessage(title, title.values)
      : title;

  return <span className={styles.container}>{renderTitle()}</span>;
}

TechInfo.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired
};

TechInfo.defaultProps = {
  title: null
};

export default injectIntl(TechInfo);
