import React from 'react';

function BlueDotsIcon() {
  return (
    <svg
      width="112px"
      height="112px"
      viewBox="0 0 112 112"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#142547">
          <circle cx="15" cy="15" r="15" />
          <circle cx="15" cy="56" r="15" />
          <circle cx="56" cy="15" r="15" />
          <circle cx="56" cy="56" r="15" />
          <circle cx="97" cy="15" r="15" />
          <circle cx="97" cy="56" r="15" />
          <circle cx="15" cy="97" r="15" />
          <circle cx="56" cy="97" r="15" />
          <circle cx="97" cy="97" r="15" />
        </g>
      </g>
    </svg>
  );
}

export default BlueDotsIcon;
