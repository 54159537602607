import React from 'react';
import PropTypes from 'prop-types';

function MobileMenuIcon(props) {
  const { fill } = props;

  return (
    <svg
      width="28"
      height="18"
      viewBox="0 0 28 18"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g transform="translate(-4 -9)" fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <path
            d="M4.5 27h27v-3h-27v3zm0-7.5h27v-3h-27v3zM4.5 9v3h27V9h-27z"
            id="a"
          />
        </mask>
        <g mask="url(#b)">
          <path
            d="M0 0h75v75H0z"
            fill={fill}
            transform="translate(-19.5 -19.5)"
          />
        </g>
      </g>
    </svg>
  );
}

MobileMenuIcon.propTypes = {
  fill: PropTypes.string
};

MobileMenuIcon.defaultProps = {
  fill: '#fff'
};

export default MobileMenuIcon;
