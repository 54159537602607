import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { isMessageDefinition } from '../../utils/message';
import styles from './Button.module.scss';

class Button extends Component {
  handleClick = event => {
    const { onPress, params } = this.props;

    if (onPress) {
      onPress(event, params);
    }
  };

  render() {
    const {
      children,
      disabled,
      className: classNameProp,
      color,
      variant,
      onPress,
      intl,
      ...rest
    } = this.props;

    const contained = variant === 'contained';
    const outlined = variant === 'outlined';
    const animated = variant === 'animated';
    const text = variant === 'text' || variant === 'outlined';
    const className = classNames(
      styles.button,
      {
        [styles.text]: text,
        [styles.textPrimary]: text && color === 'primary',
        [styles.contained]: contained,
        [styles.containedPrimary]: contained && color === 'primary',
        [styles.containedSecondary]: contained && color === 'secondary',
        [styles.outlined]: outlined,
        [styles.outlinedPrimary]: outlined && color === 'primary',
        [styles.outlinedSecondary]: outlined && color === 'secondary',
        [styles.animated]: animated,
        [styles.animatedPrimary]: animated && color === 'primary',
        [styles.animatedSecondary]: animated && color === 'secondary',
        [styles.disabled]: disabled
      },
      classNameProp
    );

    const renderChildren = () =>
      isMessageDefinition(children)
        ? intl.formatMessage(children, children.values)
        : children;

    return (
      <button
        className={className}
        type="button"
        disabled={disabled}
        onClick={this.handleClick}
        // eslint-disable-next-line react/destructuring-assignment
        aria-label={this.props['aria-label']}
        {...rest}
      >
        <span>{renderChildren()}</span>
      </button>
    );
  }
}

Button.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  onPress: PropTypes.func,
  params: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({})
  ]),
  'aria-label': PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.shape({})
  ]).isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained', 'animated'])
};

Button.defaultProps = {
  'aria-label': null,
  className: '',
  disabled: false,
  onPress: () => {},
  params: null,
  color: null,
  variant: 'text'
};

export default injectIntl(Button);
