import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import { ParallaxProvider } from 'react-scroll-parallax';
import Menu from './components/Menu';
import MobileMenu from './components/MobileMenu';
import CookieConsentBar from './components/CookieConsentBar';
import Footer from './components/Footer';
import 'typeface-rubik';
import './index.scss';

class App extends Component {
  state = {
    mobileMenuOpen: false
  };

  handleMobileMenuOpen = () => {
    this.setState({
      mobileMenuOpen: true
    });
  };

  handleMobileMenuClose = () => {
    this.setState({
      mobileMenuOpen: false
    });
  };

  render() {
    const { children } = this.props;
    const { mobileMenuOpen } = this.state;

    return (
      <IntlProvider locale="en">
        <ParallaxProvider>
          <MobileMenu
            isOpen={mobileMenuOpen}
            onClose={this.handleMobileMenuClose}
          />
          <Menu
            mobileMenuEvent={
              mobileMenuOpen
                ? this.handleMobileMenuClose
                : this.handleMobileMenuOpen
            }
          />
          {children}
          <CookieConsentBar />
          <Footer />
        </ParallaxProvider>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.node.isRequired
};

export default App;
