import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import invariant from 'invariant';
import isFunction from 'lodash/isFunction';

class SubmitButton extends Component {
  handleSubmit = () => {
    const { formik, disabled } = this.props;
    const { handleSubmit } = formik;

    // In case that the component treats disabled as informative
    if (disabled) return;

    handleSubmit();
  };

  render() {
    const {
      formik,
      disabled,
      submitCause,
      component: Button,
      ...rest
    } = this.props;
    const { isSubmitting } = formik;

    invariant(isFunction(Button), `Expected component to be a function`);

    return (
      <Button
        {...rest}
        disabled={isSubmitting || disabled}
        onPress={this.handleSubmit}
      />
    );
  }
}

SubmitButton.propTypes = {
  component: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  formik: PropTypes.shape({
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired
  }).isRequired,
  submitCause: PropTypes.string
};

SubmitButton.defaultProps = {
  disabled: false,
  submitCause: null
};

export default connect(SubmitButton);
