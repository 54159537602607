import React from 'react';
import PropTypes from 'prop-types';
import styles from './Text.module.scss';

function Text(props) {
  const { children } = props;

  return <p className={styles.text}>{children}</p>;
}

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})])
};

Text.defaultProps = {
  children: undefined
};

export default Text;
