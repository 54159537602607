import React from 'react';

function GreenSquareIcon() {
  return (
    <svg
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-127.000000, -1000.000000)"
          fill="#3AE1AC"
          fillRule="nonzero"
        >
          <g transform="translate(127.000000, 1000.000000)">
            <rect x="0" y="0" width="26.75" height="26.75" />
            <rect x="0" y="71.8" width="26.75" height="26.75" />
            <rect x="0" y="35.9" width="26.75" height="26.75" />
            <rect x="35.9" y="0" width="26.75" height="26.75" />
            <rect x="35.9" y="71.8" width="26.75" height="26.75" />
            <rect x="35.9" y="35.9" width="26.75" height="26.75" />
            <rect x="71.8" y="0" width="26.75" height="26.75" />
            <rect x="71.8" y="71.8" width="26.75" height="26.75" />
            <rect x="71.8" y="35.9" width="26.75" height="26.75" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default GreenSquareIcon;
