import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import cn from 'classnames';
import NavLink from '../NavLink';
import * as paths from '../../paths';
import styles from './MobileMenu.module.scss';

const messages = defineMessages({
  home: {
    id: 'mobileMenu.home',
    defaultMessage: 'Home'
  },
  softwareDevelopment: {
    id: 'mobileMenu.softwareDevelopment',
    defaultMessage: 'Software Development'
  },
  teamExtension: {
    id: 'mobileMenu.teamExtension',
    defaultMessage: 'Team Extension'
  },
  projects: {
    id: 'mobileMenu.projects',
    defaultMessage: 'Projects'
  },
  team: {
    id: 'mobileMenu.team',
    defaultMessage: 'The Team'
  },
  contactUs: {
    id: 'mobileMenu.contactUs',
    defaultMessage: 'Contact Us'
  }
});

function MobileMenu(props) {
  const { isOpen, onClose } = props;

  return (
    <div
      className={cn(styles.root, {
        [styles.visible]: isOpen
      })}
      onClick={onClose}
      role="presentation"
    >
      <div className={styles.container}>
        <ul className={styles.items}>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles.item}
              exact
              to={paths.mainPage()}
            >
              <FormattedMessage {...messages.home} />
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles.item}
              exact
              to={paths.softwareDevelopmentPage()}
            >
              <FormattedMessage {...messages.softwareDevelopment} />
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles.item}
              exact
              to={paths.teamExtensionPage()}
            >
              <FormattedMessage {...messages.teamExtension} />
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles.item}
              exact
              to={paths.projectsPage()}
            >
              <FormattedMessage {...messages.projects} />
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles.item}
              exact
              to={paths.teamPage()}
            >
              <FormattedMessage {...messages.team} />
            </NavLink>
          </li>
          <li>
            <a href="#Contact" className={styles.item}>
              <FormattedMessage {...messages.contactUs} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

MobileMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default MobileMenu;
