import React, { Component } from 'react';
import { Field as FormikField, getIn } from 'formik';
import PropTypes from 'prop-types';

class Field extends Component {
  handleChange = newValue => {
    const { name } = this.props;
    const oldValue = this.form.values[name];

    if (newValue === oldValue) return;

    this.form.setFieldValue(name, newValue);
  };

  handleFocus = () => {
    const { name } = this.props;
    this.form.setFieldValue('_focusedFormField', name);
  };

  handleBlur = () => {
    const { name } = this.props;
    this.form.setFieldTouched(name, true);

    // eslint-disable-next-line no-underscore-dangle
    if (this.form.values._focusedFormField === name)
      this.form.setFieldValue('_focusedFormField', undefined);
  };

  renderInput = ({ form, field }) => {
    const { component: Input, name, innerRef, ...rest } = this.props;
    const { errors, touched, submitCount } = form;
    const error = getIn(errors, name);
    const invalid = error && (getIn(touched, name) || !!submitCount);

    this.form = form;

    return (
      <Input
        {...rest}
        name={name}
        ref={innerRef}
        form={form}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        value={field.value}
        {...(invalid ? { errorMessage: error } : {})}
      />
    );
  };

  render() {
    const { component, ...rest } = this.props;

    return <FormikField render={this.renderInput} {...rest} />;
  }
}

Field.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.shape({})
  ]).isRequired,
  id: PropTypes.string,
  innerRef: PropTypes.func,
  name: PropTypes.string.isRequired
};

Field.defaultProps = {
  id: null,
  innerRef: null
};

export default Field;
