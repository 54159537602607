import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import invariant from 'invariant';
import hoistNonReactStatics from 'hoist-non-react-statics';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';

function withForm(options = {}) {
  const { name } = options;

  return WrappedComponent => {
    invariant(
      isString(name) || isFunction(name),
      'Form expected to be a string or a function returning a string!'
    );

    class Form extends Component {
      componentDidMount() {
        const { navigation, submitForm, isSubmitting } = this.props;
        if (navigation) {
          navigation.setParams({ onSubmit: submitForm, isSubmitting });
        }
      }

      componentDidUpdate(prevProps) {
        const { formState } = this.props;

        if (prevProps.formState !== formState) {
          this.updateFormState(formState);
        }
      }

      updateFormState = formState => {
        const {
          setSubmitting,
          setErrors,
          onSubmitFail,
          onSubmitSuccess,
          setStatus
        } = this.props;
        const {
          isSubmitting = false,
          isFailed = false,
          isSucceeded = false,
          error,
          response
        } = formState;

        setSubmitting(isSubmitting);
        setStatus({
          isFailed,
          isSucceeded,
          error
        });

        if (isFailed) {
          const { fields } = error;
          if (fields) {
            setErrors(fields);
          }
          if (onSubmitFail) {
            onSubmitFail(error);
          }
        }

        if (isSucceeded) {
          onSubmitSuccess({
            ...this.props,
            response
          });
        }
      };

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    Form.propTypes = {
      formState: PropTypes.shape({}),
      isSubmitting: PropTypes.bool,
      navigation: PropTypes.shape({
        setParams: PropTypes.func
      }),
      onSubmitFail: PropTypes.func,
      onSubmitSuccess: PropTypes.func,
      resetForm: PropTypes.func.isRequired,
      setErrors: PropTypes.func.isRequired,
      setStatus: PropTypes.func.isRequired,
      setSubmitting: PropTypes.func.isRequired,
      status: PropTypes.shape({}),
      submitForm: PropTypes.func.isRequired
    };

    Form.defaultProps = {
      formState: {},
      isSubmitting: false,
      navigation: null,
      onSubmitFail: null,
      onSubmitSuccess: () => {},
      status: null
    };

    hoistNonReactStatics(Form, WrappedComponent);

    return withFormik({
      // Default mapPropsToValues just uses the initialValues from options, props or empty
      mapPropsToValues: ({ initialValues }) =>
        options.initialValues || initialValues || {},
      ...options,
      handleSubmit: async (values, formikBag) => {
        const { props } = formikBag;
        const onSubmit = options.onSubmit || props.onSubmit;

        onSubmit({ ...formikBag, values });
      }
    })(Form);
  };
}

export default withForm;
