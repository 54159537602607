export const mainPage = () => '/';
export const softwareDevelopmentPage = () => '/software-development';
export const teamExtensionPage = () => '/team-extension';
export const projectsPage = () => '/projects';
export const teamPage = () => '/team';
export const privacyPolicy = () => '/privacy-policy';
export const termsAndConditions = () => '/terms-and-conditions';

// case studies

export const courtly = () => '/cases/courtly';
export const nexogen = () => '/cases/nexogen';
export const namedrop = () => '/cases/namedrop';
export const dataIntegration = () => '/cases/data-integration';
export const saasStory = () => '/cases/saas-story';
export const recart = () => '/cases/recart';
