import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import NavLink from '../NavLink';
import Container from '../Container';
import Logo from '../../assets/icons/Logo';
// import FacebookIcon from '../../assets/icons/FacebookIcon';
// import TwitterIcon from '../../assets/icons/TwitterIcon';
import LinkedInIcon from '../../assets/icons/LinkedInIcon';
import * as paths from '../../paths';
import styles from './Footer.module.scss';

const messages = defineMessages({
  about: {
    id: 'footer.about',
    defaultMessage: 'About'
  },
  caseStudies: {
    id: 'footer.caseStudies',
    defaultMessage: 'Case studies'
  },
  contactUs: {
    id: 'footer.contactUs',
    defaultMessage: 'Contact us'
  },
  softwareDevelopment: {
    id: 'footer.softwareDevelopment',
    defaultMessage: 'Software Development'
  },
  teamExtension: {
    id: 'footer.teamExtension',
    defaultMessage: 'Team Extension'
  },
  projects: {
    id: 'footer.projects',
    defaultMessage: 'Projects'
  },
  team: {
    id: 'footer.team',
    defaultMessage: 'The Team'
  },
  courtly: {
    id: 'footer.courtly',
    defaultMessage: 'Courtly'
  },
  nexogen: {
    id: 'footer.nexogen',
    defaultMessage: 'Nexogen'
  },
  namedrop: {
    id: 'footer.namedrop',
    defaultMessage: 'Namedrop'
  },
  dataIntegration: {
    id: 'footer.dataIntegration',
    defaultMessage: 'Data Integration'
  },
  saasStory: {
    id: 'footer.saasStory',
    defaultMessage: 'SaaS Story'
  },
  recart: {
    id: 'footer.recart',
    defaultMessage: 'Recart'
  },
  hunAddress: {
    id: 'footer.hunAddress',
    defaultMessage: 'HUNGARY {br} 32 Pannonia Street, Budapest, 1136'
  },
  usaAddress: {
    id: 'footer.usaAddress',
    defaultMessage: 'FLORIDA {br} 7901 4th St N #5122, St. Petersburg, FL 33702'
  },
  emailAddress: {
    id: 'footer.emailAddress',
    defaultMessage: 'hello@streambright.io'
  },
  copyRight: {
    id: 'footer.copyRight',
    defaultMessage: 'All rights reserved ©  2019, Streambright'
  },
  pipe: {
    id: 'footer.pipe',
    defaultMessage: '|'
  },
  privacy: {
    id: 'footer.email',
    defaultMessage: 'PRIVACY'
  },
  terms: {
    id: 'footer.terms',
    defaultMessage: 'TERMS'
  }
});

function Footer() {
  return (
    <div className={styles.container}>
      <Container className={styles.contentContainer}>
        <NavLink className={styles.logo} to={paths.mainPage()}>
          <Logo />
        </NavLink>
        <div className={styles.items}>
          <div className={styles.title}>
            <FormattedMessage {...messages.about} />
          </div>
          <NavLink
            activeClassName={styles.active}
            className={styles.item}
            exact
            to={paths.softwareDevelopmentPage()}
          >
            <FormattedMessage {...messages.softwareDevelopment} />
          </NavLink>
          <NavLink
            activeClassName={styles.active}
            className={styles.item}
            exact
            to={paths.teamExtensionPage()}
          >
            <FormattedMessage {...messages.teamExtension} />
          </NavLink>
          <NavLink
            activeClassName={styles.active}
            className={styles.item}
            exact
            to={paths.projectsPage()}
          >
            <FormattedMessage {...messages.projects} />
          </NavLink>
          <NavLink
            activeClassName={styles.active}
            className={styles.item}
            exact
            to={paths.teamPage()}
          >
            <FormattedMessage {...messages.team} />
          </NavLink>
        </div>
        <div className={styles.items}>
          <div className={styles.title}>
            <FormattedMessage {...messages.caseStudies} />
          </div>
          <NavLink
            activeClassName={styles.active}
            className={styles.item}
            exact
            to={paths.courtly()}
          >
            <FormattedMessage {...messages.courtly} />
          </NavLink>
          <NavLink
            activeClassName={styles.active}
            className={styles.item}
            exact
            to={paths.namedrop()}
          >
            <FormattedMessage {...messages.namedrop} />
          </NavLink>
          <NavLink
            activeClassName={styles.active}
            className={styles.item}
            exact
            to={paths.saasStory()}
          >
            <FormattedMessage {...messages.saasStory} />
          </NavLink>
          <NavLink
            activeClassName={styles.active}
            className={styles.item}
            exact
            to={paths.nexogen()}
          >
            <FormattedMessage {...messages.nexogen} />
          </NavLink>
        </div>
        <div className={styles.items}>
          <div className={styles.title}>
            <FormattedMessage {...messages.contactUs} />
          </div>
          <div className={styles.data}>
            <FormattedMessage
              {...messages.usaAddress}
              values={{ br: <br /> }}
            />
          </div>
          <div className={styles.data}>
            <FormattedMessage
              {...messages.hunAddress}
              values={{ br: <br /> }}
            />
          </div>
          <a href="#Contact" className={styles.item}>
            <FormattedMessage {...messages.emailAddress} />
          </a>
        </div>
        {/*
        <div className={styles.icons}>
          <FacebookIcon />
          <TwitterIcon />
        </div>
        */}
      </Container>
      <div className={styles.copyRight}>
        <div>
          <FormattedMessage {...messages.copyRight} />
          <a
            href="https://www.linkedin.com/company/streambright/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.icons}
          >
            <LinkedInIcon style={{ marginLeft: 15 }} />
          </a>
        </div>
        <div className={styles.privacy}>
          <NavLink
            activeClassName={styles.active}
            className={styles.item}
            exact
            to={paths.privacyPolicy()}
          >
            <FormattedMessage {...messages.privacy} />
          </NavLink>
          <div>
            <FormattedMessage {...messages.pipe} />
          </div>
          <NavLink
            activeClassName={styles.active}
            className={styles.item}
            exact
            to={paths.termsAndConditions()}
          >
            <FormattedMessage {...messages.terms} />
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Footer;
