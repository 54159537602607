import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import cn from 'classnames';
import NavLink from '../NavLink';
import Button from '../Button';
import MobileMenuIcon from '../../assets/icons/MobileMenuIcon';
import Logo from '../../assets/icons/Logo';
import LogoSticky from '../../assets/icons/LogoSticky';
import * as paths from '../../paths';
import styles from './Menu.module.scss';

const messages = defineMessages({
  softwareDevelopment: {
    id: 'menu.softwareDevelopment',
    defaultMessage: 'Software Development'
  },
  teamExtension: {
    id: 'menu.teamExtension',
    defaultMessage: 'Team Extension'
  },
  projects: {
    id: 'menu.projects',
    defaultMessage: 'Projects'
  },
  team: {
    id: 'menu.team',
    defaultMessage: 'The Team'
  },
  letsTalk: {
    id: 'menu.letsTalk',
    defaultMessage: `Let's talk`
  }
});

const Menu = ({ mobileMenuEvent }) => {
  const [scrolledDown, setScrolledDown] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.pageYOffset < 50 ? scrolledDown : !scrolledDown) {
      setScrolledDown(!scrolledDown);
    }
  }, [scrolledDown, setScrolledDown]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div
      className={cn(styles.root, {
        [styles.scrolledDown]: scrolledDown
      })}
    >
      <div className={styles.container}>
        <NavLink
          className={scrolledDown ? styles.logoSticky : styles.logo}
          to={paths.mainPage()}
        >
          {scrolledDown ? <LogoSticky /> : <Logo />}
        </NavLink>
        <Button className={styles.mobileMenu} onClick={mobileMenuEvent}>
          <MobileMenuIcon fill={scrolledDown ? '#142547' : '#fff'} />
        </Button>
        <div className={styles.items}>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles.item}
              exact
              to={paths.softwareDevelopmentPage()}
            >
              <FormattedMessage {...messages.softwareDevelopment} />
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles.item}
              exact
              to={paths.teamExtensionPage()}
            >
              <FormattedMessage {...messages.teamExtension} />
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles.item}
              exact
              to={paths.projectsPage()}
            >
              <FormattedMessage {...messages.projects} />
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles.item}
              exact
              to={paths.teamPage()}
            >
              <FormattedMessage {...messages.team} />
            </NavLink>
          </li>
          <a href="#Contact">
            <Button
              variant="animated"
              color="secondary"
              className={styles.item}
            >
              <span>
                <span>
                  <FormattedMessage {...messages.letsTalk} />
                </span>
              </span>
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  mobileMenuEvent: PropTypes.func.isRequired
};

export default Menu;
