import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cn from 'classnames';
import { isMessageDefinition } from '../../utils/message';
import Container from '../Container';
import styles from './Hero.module.scss';

function Hero(props) {
  const {
    title,
    description,
    icon,
    image: Image,
    backgroundImage: BackgroundImage,
    imageClassName,
    intl
  } = props;

  const renderDescription = () =>
    isMessageDefinition(description)
      ? intl.formatMessage(description, description.values)
      : description;

  const renderTitle = () =>
    isMessageDefinition(title)
      ? intl.formatMessage(title, title.values)
      : title;

  return (
    <>
      <div className={styles.container}>
        <BackgroundImage
          className={styles.mainPageHeroBackgroundImage}
          style={{ position: 'absolute' }}
          critical
          fadeIn={false}
        />
        <Container className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <div className={styles.title}>{renderTitle()}</div>
            <div className={styles.description}>{renderDescription()}</div>
          </div>
          {Image && (
            <Image
              className={cn(styles.imageContainer, imageClassName)}
              imgStyle={{ objectFit: 'contain' }}
              critical
              fadeIn={false}
            />
          )}
        </Container>
        <div className={styles.icon}>{icon}</div>
      </div>
    </>
  );
}

Hero.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  icon: PropTypes.node,
  backgroundImage: PropTypes.elementType.isRequired,
  image: PropTypes.node,
  imageClassName: PropTypes.string
};

Hero.defaultProps = {
  title: null,
  description: null,
  icon: null,
  image: null,
  imageClassName: undefined
};

export default injectIntl(Hero);
