import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import cn from 'classnames';

const NavLink = ({ activeClassName, className, exact, ...props }) => (
  <Link
    {...props}
    getProps={({ isCurrent, isPartiallyCurrent }) => ({
      className: cn(className, {
        [activeClassName]: isCurrent || (!exact && isPartiallyCurrent)
      })
    })}
  />
);

NavLink.propTypes = {
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  exact: PropTypes.bool
};

NavLink.defaultProps = {
  className: undefined,
  activeClassName: undefined,
  exact: false
};

export default NavLink;
