import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import invariant from 'invariant';
import isFunction from 'lodash/isFunction';

function StatusMessage(props) {
  const { component: TextComponent, formik, ...rest } = props;
  const { status } = formik;

  invariant(isFunction(TextComponent), `Expected component to be a function`);

  if (status && status.error && status.error.message) {
    return <TextComponent {...rest}>{status.error.message}</TextComponent>;
  }

  return null;
}

StatusMessage.propTypes = {
  component: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    status: PropTypes.shape({
      error: PropTypes.shape({
        message: PropTypes.string
      })
    })
  }).isRequired
};

export default connect(StatusMessage);
