import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import setLocale from 'yup/lib/setLocale';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import { withForm, Field, StatusMessage, SubmitButton } from '../withForm';
import Text from '../Text';
import Button from '../Button';
import TextInput from '../TextInput';
import TextArea from '../TextArea';
import { isMessageDefinition } from '../../utils/message';
import styles from './ContactSection.module.scss';
import { postMessage } from '../../services/StreamBrightApi';

const messages = defineMessages({
  button: {
    id: 'projectsPage.button',
    defaultMessage: 'Get in touch'
  },
  firstName: {
    id: 'projectsPage.firstName',
    defaultMessage: 'First name'
  },
  lastName: {
    id: 'projectsPage.lastName',
    defaultMessage: 'Last name'
  },
  email: {
    id: 'projectsPage.email',
    defaultMessage: 'Email'
  },
  message: {
    id: 'projectsPage.message',
    defaultMessage: 'Your message'
  },
  success: {
    id: 'projectsPage.success',
    defaultMessage: 'Your message was successfully sent.'
  },
  required: {
    id: 'error.required',
    defaultMessage:
      '{path, select, firstName {First name} lastName {Last name} email {Email} message {Message} other {}} is a required field.'
  }
});

setLocale({
  mixed: {
    required: ({ path }) => ({
      ...messages.required,
      values: { path }
    })
  }
});

function ContactSection(props) {
  const { title, description, intl, status } = props;
  const { isSucceeded } = status || {};

  const renderTitle = () =>
    isMessageDefinition(title)
      ? intl.formatMessage(title, title.values)
      : title;

  const renderDescription = () =>
    isMessageDefinition(description)
      ? intl.formatMessage(description, description.values)
      : description;

  return (
    <div className={styles.container}>
      <div className={styles.background} />
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>{renderTitle()}</h1>
        <p className={styles.description}>{renderDescription()}</p>
        {isSucceeded && (
          <p className={styles.success}>
            <FormattedMessage {...messages.success} />
          </p>
        )}
        {!isSucceeded && (
          <>
            <div className={styles.formContainer}>
              <form>
                <div className={styles.nameContainer}>
                  <Field
                    component={TextInput}
                    name="firstName"
                    placeholder={messages.firstName}
                  />
                  <Field
                    component={TextInput}
                    name="lastName"
                    placeholder={messages.lastName}
                  />
                </div>
                <div>
                  <Field
                    component={TextInput}
                    name="email"
                    placeholder={messages.email}
                  />
                  <div className={styles.message}>
                    <Field
                      component={TextArea}
                      name="message"
                      placeholder={messages.message}
                    />
                  </div>
                </div>
                <StatusMessage component={Text} />
              </form>
            </div>
            <SubmitButton variant="animated" color="primary" component={Button}>
              <span>
                <span>
                  <FormattedMessage {...messages.button} />
                </span>
              </span>
            </SubmitButton>
          </>
        )}
      </div>
    </div>
  );
}

const validationSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string()
    .email()
    .required(),
  message: Yup.string().required()
});

ContactSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  status: PropTypes.shape({
    isSucceeded: PropTypes.bool
  })
};

ContactSection.defaultProps = {
  status: undefined
};

ContactSection.defaultProps = {
  title: null,
  description: null
};

export default withForm({
  name: 'sendMessage',
  validationSchema,
  onSubmit: ({ values, resetForm, setStatus, setSubmitting }) => {
    postMessage(values).then(
      () => {
        resetForm();
        setStatus({
          isFailed: false,
          isSucceeded: true,
          error: undefined
        });
        setSubmitting(false);
      },
      () => {
        setStatus({
          isFailed: true,
          isSucceeded: false,
          error: { message: 'An error has occurred, please try again later!' }
        });
        setSubmitting(false);
      }
    );
  }
})(injectIntl(ContactSection));
