import Fetcher from '../utils/Fetcher';

const fetcher = new Fetcher({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

export const postMessage = fetcher.apiCall(
  ({ firstName, lastName, email, message }) => ({
    method: 'POST',
    url: 'https://83omp3kz6b.execute-api.us-east-1.amazonaws.com/prod',
    body: {
      firstName,
      lastName,
      email,
      message
    }
  })
);
